/* eslint-disable react/jsx-filename-extension */
import React from 'react';

import Button from 'elements/Button';
import heroPortfolio from 'assets/images/Logo.png';

export default function BrandIcon() {
  return (
    <Button
      className=".col-sm-12"
      type="link"
      href="/"
    >
      <img src={heroPortfolio} alt="" width="65%" />
    </Button>
  );
}
